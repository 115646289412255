import React from "react"

const BlockContent = require('@sanity/block-content-to-react')

const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
      divider: props => (
        <hr />
      )
    }
  }

  

const staffLink = ({ post }) => (

  <div className="banner-content">
    <h1 id={post.slug.current} name={post.slug.name}>{post.name}</h1>
    {post.imageUrl && <img src={post.imageUrl} className="staff-photo" alt={post.name}></img> }
    <div className="staff-bio">
        <BlockContent blocks={post.bio} serializers={serializers} projectId='a8s3bknf' dataset='production' />
    </div>
    
  </div>
)

export default staffLink